/*Este componente NotFound representa una página de error 404,
 utilizada cuando una ruta no es encontrada en la aplicación. */
 
import { Col, Row } from "antd";
import "./NotFound.scss";
import { Button } from "@chakra-ui/react";
import Logo from "../../assets/logos/logoBlackUni.svg";
import NotFoundI from "../../assets/logos/404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const accessToken = sessionStorage.getItem("newCheckToken");
    if (accessToken) return navigate("/");
    navigate("/exit");
  };

  return (
    <div className="not-found-container">
      <div className="blue-container">
        <div className="newcheck-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="notfound-logo">
          <img src={NotFoundI} alt="404" />
        </div>
      </div>

      <div className="white-container">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <span>😮</span>
          </Col>
          <Col span={24}>
            <h3>Ouch! No hemos podido encontrar esta página</h3>
          </Col>
          <Col span={24}>
            <p>
            Ha ocurrido un error, comuníquese con el banco
            </p>
          </Col>
          <Col span={24}>
            <Button
              size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
              fontWeight="600"
              bg="UniOrange.700"
              colorScheme="UniOrange"
              color="UniOrange.50"
              type="submit"
              id="submit-button"
              onClick={handleNavigate}
            >
              Ir al inicio
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotFound;
