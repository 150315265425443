import { memo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "pages/404/NotFound";
import LivenessStep from "pages/NewNaturalClient/components/SelfiStep/SelfieStep";
import DocIdStep from "pages/NewNaturalClient/components/DocIdStep/DocIdStep";
import OcrStep from "pages/NewNaturalClient/components/OcrStep/OcrStep";
import LoadingOnb from "pages/NewNaturalClient/components/Loading/Loading";
import ExitOnb from "pages/NewNaturalClient/components/Exit/exit";
// import { LivenessError } from "pages/NewNaturalClient/components/SelfiStep/componentes/LivenessError";

const Router = memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        {/*PRIMERA QUE VERIFICA - CONDICION CON REQUESTNUMBER */}
        <Route path="/doc" element={<DocIdStep />} />

        {/*CONDICION SI LA DATA ESTA VACIA O NO */}
        <Route path="/ocr" element={<OcrStep />} />
        <Route path={`/load/:requestNumber/:accessToken/success`} element={<LoadingOnb />} />
        <Route path="/:userId" element={<LivenessStep />} />
        <Route path="/exit" element={<ExitOnb />} />

        <Route path="/*" element={<NotFound />} />
        {/* 
        <Route
          path="/"
          element={<Navigate to="/alau6480/jkfhdhfhkds" replace />}
        /> */}

        {/* <Route path="/selfie-error" element={<LivenessError />} />  */}
      </Routes>
    </BrowserRouter>
  );
});
Router.displayName = "Router";
export default Router;

/* useEffect(() => {
    // FUNCION PARA BLOQUEAR EL GO BACK 

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    // FUNCION PARA BLOQUEAR EL GO BACK 
  }, []); */
