import React, { memo, useState, useEffect, useRef } from "react";
import "./DocIdStep.scss";
import Camera, { IMAGE_TYPES } from "react-html5-camera-photo";
import Footer from "templates/FormTemplate/components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import { postDoc, postImageForm } from "service/newNaturalClient/formOne";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import IconId from "../../../../assets/icons/id-scan.svg";
import { useNavigate } from "react-router-dom";

// Función para recortar la imagen
const cropImage = (dataUri, cropWidth, cropHeight, cropX, cropY) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.fillStyle = "#0000008C";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 350, 70, 1460, 1080, 0, 0, 1920, 1380);

      resolve(canvas.toDataURL("image/jpeg"));
    };
    img.src = dataUri;
  });
};



const DocIdStep = memo(() => {

  const [Picture, setPicture] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [firstScreen, setFirstScreen] = useState("SECOND");
  const dispatch = useDispatch();
const navigate = useNavigate();
  const clickOnHideCameraButton = () => {
    const button = document.getElementById("outer-circle");
    if (button) button.click();
    setFirstScreen("THREE");
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {

        setPicture(reader.result);

        setFirstScreen("THREE");

      };
      reader.readAsDataURL(file);
    }
  };
  const onTakePhoto = React.useCallback(async (dataUri) => {
    const croppedImage = await cropImage(dataUri, 1640, 1480, 0, 0);
    sessionStorage.setItem("img-preview", croppedImage);
    setPicture(croppedImage);
    setFirstScreen("THREE");
  }, []);

  // const handleRepit = React.useCallback(() => {
  //   sessionStorage.removeItem("img-preview");
  //   setFirstScreen("SECOND");
  //   setPicture(undefined);
  // }, []);

  const handleSentPhoto = async () => {
    setLoading(true);
    const body = {
      // requestNumber: sessionStorage.getItem("requestNumber"),
      image: Picture,
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    console.log("RESPUESTA FORMDATA", formData)
    try {
      const res = await postDoc(formData);
      console.log("RESPUESTA DOC", res)
      if (res.data.requestNumber) {
        
        navigate("/ocr");
        dispatch(setNewNaturalStep(parseInt(res.data)));
        dispatch(setFormTrackInfo(res.data));
      } else {

      }
    } catch (error) {
      console.log("DOCUMENTO:", error);
      ErrorCToast(error);
      setFirstScreen("SECOND"); 
    } finally {
      setLoading(false);
    }
  };

  const handleRepit = () => {
    setFirstScreen("SECOND");
    setPicture(undefined);
  };


 

  return (
    <div className="vista">
      {firstScreen === "SECOND" && (
        <>
          <Navbar title="Captura de cédula" noBackbutton={true} />
          <div style={{ height: "250px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: "25px" }}>
            <img id="face-logo" src={IconId} alt="Icon" />
            <p style={{ marginTop: "16px" }}>
        Prepárese para escanear 
              <br />
              su cédula de Panamá o Pasaporte
            </p>
          </div>
          <div className="camera-container">
            <input
              type="file"
              accept="image/*"
              capture="environment" // Esto abre la cámara nativa
              onChange={handleImageChange}
              style={{ display: "none" }} // Ocultar el input
              id="camera-input"
            />
            <button
              className="big-button"
              onClick={() => document.getElementById('camera-input').click()}
            >
              Capturar
            </button>
          </div>
          {/* <Footer onClick={() => document.getElementById('camera-input').click()} camera /> */}
        </>
      )}
      {firstScreen === "THREE" && (
        <>
          <Navbar title="Captura de documento de identidad" noHomebutton={true}  />
      
            <div className="container-preview">
            <img  src={Picture} alt="preview" className="image-preview" />
       
            </div>
          <Footer
            onClick={handleSentPhoto}
            loading={loading}
            preview
            handleRepit={handleRepit}
          />
        </>
      )}
      {firstScreen === "FIRST" && (
        <div>
          <Navbar title="Captura de documento de identidad"  noHomebutton={true} />
          <div
            style={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "150px" }}></div>
            <img id="face-logo" src={IconId} />
            <p style={{ marginTop: "16px" }}>
              Prepárese para escanear su
              <br />
              cédula de Panameña o Pasaporte
            </p>
          </div>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <button
              style={{ marginTop: "100px" }}
              className="big-button"
              onClick={() => {
                setFirstScreen("SECOND");
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

DocIdStep.displayName = "DocIdStep";
export default DocIdStep;